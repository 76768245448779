import React, { FunctionComponent } from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";

import Layout from "../shared/layout";
import SEO from "../shared/seo";

const NotFoundPage: FunctionComponent = () => (
  <Layout>
    <SEO title="404" />
    <h1 className="text-center m-5">404</h1>
    <p className="text-center m-2">
      <FormattedMessage id="common.not-found" />
    </p>
  </Layout>
);

export default NotFoundPage;
